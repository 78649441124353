import { Navigate, createBrowserRouter } from "react-router-dom";
import { PATH } from "./utils/pagePath";
import PageNotFound from "./components/PageNotFound";
import React, { Suspense, lazy } from "react";
import PostLogin from "./layouts/PostLogin";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import Spinner from "./components/Spinner";

const LazyPreLogin = lazy(() => import("./layouts/PreLogin"));
const LazyLogin = lazy(() => import("./pages/Login"));
const LazyRegister = lazy(() => import("./pages/Register"));
const LazyForgotPassword = lazy(() => import("./pages/ForgetPassword"));
const LazyOtp = lazy(() => import("./pages/Otp"));
const LazyLoginWithOtp = lazy(() => import("./pages/Login_With_Otp"));

const LazyDashboard = lazy(() => import("./pages/Dashboard"));

const LazyCandidates = lazy(() => import("./pages/Candidates"));
const LazySubscription = lazy(() => import("./pages/Settings/Subscription"));
const LazyCandidateDetails = lazy(() =>
  import("./pages/Candidates/CandidateDetails")
);
const LazyApplication = lazy(() =>
  import("./pages/Candidates/CandidateDetails/Application")
);
const LazyResume = lazy(() =>
  import("./pages/Candidates/CandidateDetails/Resume")
);
const LazyEvents = lazy(() =>
  import("./pages/Candidates/CandidateDetails/Events")
);

const LazyCareer = lazy(() => import("./pages/Career"));
const LazyCareerDetail = lazy(() => import("./pages/Career/CareerDetail"))
const LazyJobs = lazy(() => import("./pages/Jobs"));
const LazyJobDetail = lazy(() => import("./pages/Jobs/JobDetail"));

const LazyStages = lazy(() => import('./pages/Stages'));

const LazySettings = lazy(() => import("./pages/Settings"));
const LazyUser = lazy(() => import("./pages/Settings/User"));
const LazyTeams = lazy(() => import("./pages/Settings/Teams"));
const LazyQualification = lazy(() => import("./pages/Settings/CustomizedQualification"));
const LazyAccount = lazy(() => import("./pages/Settings/Account"));
const LazyCompany = lazy(() => import("./pages/Settings/Company"));
const LazySettingsCandidate = lazy(() => import("./pages/Settings/Candidate"));
const LazySkills = lazy(() => import("./pages/Settings/Skills"));
const LazyAgency = lazy(() => import("./pages/Settings/Agency"));
const LazyEventType = lazy(() => import("./pages/Settings/EventType"));
const LazyRecruiter = lazy(() => import("./pages/Settings/Recruiter"))
const LazySetPassword = lazy(() => import("./pages/Set-Password/index"))
// const LazyApplyCandidate = lazy(() => import("./pages/Candidates/PublicApplyCandidates"));
export const router = createBrowserRouter([
  {
    path: PATH.DEFAULT,
    element: <Navigate to={PATH.LOGIN} replace />,
  },
  {
    element: (
      <PublicRoute>
        <Suspense fallback={<Spinner />}>

        <LazyPreLogin />
        </Suspense>
      </PublicRoute>
    ),
    children: [
      {
        path: PATH.LOGIN,
        element: <LazyLogin />,
      },
      {
        path: PATH.INVITE_USER,
        element: <LazyRegister />,
      },
      {
        path: PATH.REGISTER,
        element: <LazyRegister />,
      },
      {
        path: PATH.FORGOT_PASSWORD,
        element: <LazyForgotPassword />,
      },
      {
        path: PATH.SET_PASSWORD,
        element: <LazySetPassword />,
      },
      {
        path: PATH.OTP,
        element: <LazyOtp />,
      },
      {
        path: PATH.LOGIN_WITH_OTP,
        element: <LazyLoginWithOtp />,
      },

    ],
  },
  {
    element: (
        <PostLogin />
    ),
    children: [
    {
      path: `${PATH.CAREER}/:id`,
      element: <LazyCareer />
    },
    {
      path: `${PATH.CAREER}/:careerID${PATH.POSTED_JOB_VIEW}/:jobID`,
      element: <LazyCareerDetail />
    },
    ]
  },
  {
    element: (
      <ProtectedRoute>
        <PostLogin />
      </ProtectedRoute>
    ),
    children: [
      {
        path: PATH.DASHBOARD,
        element: <LazyDashboard />,
      },
      // {
      //   path: `${PATH.CAREER}/:id`,
      //   element: <LazyCareer />
      // },
      // {
      //   path: `${PATH.CAREER}/:careerID${PATH.POSTED_JOB_VIEW}/:jobID`,
      //   element: <LazyCareerDetail />
      // },

      {
        path: PATH.CANDIDATES,
        element: <LazyCandidates />,
      },
      {
        path: `${PATH.CANDIDATES}/:id`,
        element: <LazyCandidateDetails />,
        children: [
          {
            path: `${PATH.CANDIDATES}/:id${PATH.APPLICATION}`,
            element: <LazyApplication />,
          },
          {
            path: `${PATH.CANDIDATES}/:id${PATH.RESUME}`,
            element: <LazyResume />,
          },
          {
            path: `${PATH.CANDIDATES}/:id${PATH.EVENTS}`,
            element: <LazyEvents />,
          },
        ],
      },
      {
        path: PATH.JOBS,
        element: <LazyJobs />,
      },
      {
        path: `${PATH.JOBS}/:id`,
        element: <LazyJobDetail privateRoute={true} />,
      },
      {
        path: PATH.STAGES,
        element: <LazyStages />,
      },
      {
        path: PATH.SETTINGS,
        element: <LazySettings />,
        children: [
          {
            path: PATH.ACCOUNT,
            element: <LazyAccount />,
          },
          {
            path: PATH.COMPANY,
            element: <LazyCompany />,
          },
          {
            path: PATH.SETTING_CANDIDATE,
            element: <LazySettingsCandidate />,
          },
          {
            path: PATH.USER,
            element: <LazyUser />,
          },
          {
            path: PATH.TEAMS,
            element: <LazyTeams />,
          },
          {
            path: PATH.SUBSCRIPTION,
            element: <LazySubscription />,
          },
          {
            path: PATH.QUALIFICATION,
            element: <LazyQualification />,
          },
          {
            path: PATH.SKILLS,
            element: <LazySkills />,
          },
          {
            path: PATH.AGENCY,
            element: <LazyAgency />,
          },
          {
            path: PATH.RECRUITER,
            element: <LazyRecruiter />,
          },
          {
            path: PATH.EDIT_EVENT_TYPE,
            element: <LazyEventType />,
          },
        ],
      },
    ],
  },
  {
    element: <PageNotFound />,
    path: PATH.PAGE_NOT_FOUND,
  },

  // {
  //   element: (
  //     <PublicRoute>
  //       <LazyApplyCandidate />
  //     </PublicRoute>
  //   ),
  //   path: PATH.CANDIDATE_APPLY,
  // },

]);
