import React from "react";
import { Navigate,useLocation } from "react-router-dom";
import { PATH } from "../../utils/pagePath";
import { Cookies } from "react-cookie";
import { COOKIES } from "../../utils/constants";

const Index = (props) => {
  let cookie = new Cookies();
  const location = useLocation();
  const isSignedIn = cookie.get(COOKIES.ACCESS_TOKEN); //add here token storage location;

  if (!isSignedIn) {
    return <Navigate to={PATH.LOGIN} replace  state={{from : location}}/>;
  }

  return props.children;
};

export default Index;
