export const API_ENDPOINTS = {
  REGISTER: "users/register",
  INVITE_REGISTER: "users/invites/register",
  LOGIN: "auth/login",
  FORGOT_PASSWORD: "users/password/forgot/initiate",
  OTP_VEERIFY: "users/password/forgot/verify",
  OTP_INITIATE: "auth/login/otp/initiate",
  PASSWORD_RESET: "users/password/forgot/reset",
  LOGIN_VERFIFY: "auth/login/otp/verify",
  GOOGLE_LOGIN: "auth/login/google",
  SET_PASSWORD: "users/password/set",
  ACCOUNT_PASSWORD_RESET: "users/password/reset",

  //team
  TEAMS: "teams/",
  MEMBERS: "members",
  MEMBER: "member",
  STAGES: 'stages/',
  SSTAGES: 'stage/',
  STAGE: 'stage-list/',
  UPDATE_STAGE: "update-stages/",
  DELETE_STAGE: "stages/delete",
  //agency
  AGENCY: "agency/",

  //users
  USERS: "users",

  //dashboard
  DASHBOARD: "dashboard",
  CANDIDATE_EXP: "/candidate-experience-graph",
  CANDIDATE_PACKAGE: "/candidate-package-graph",

  JOB_REPORT: "/job-report",
  AGENCY_REPORT: "/agency-report",
  RECRUITER_REPORT: '/recruiter-report',
  JOB_STATUS_REPORT: "/job-status-report",
  JOB_REPORT_TABLE: "/job-profile-status-report-table",
  RECRUITER_REPORT_TABLE: "/recruiter-status-report-table",
  

  //careers
  CAREER_INITIATE_OTP: "careers/otp/initiate",
  VERIFY_CAREER_OTP: "careers/otp/verify",
  GET_CAREERS: "careers",

  //users
  USER: "users/",
  GET_USERS: "users/",
  POST_USER: "users/",
  UPDATE_USER: "users",
  DELETE_USER: "users",
  GET_RECRUITER: "recruiter-list",
  SEARCH_RECRUITER: "/users/recruiter-list",
  CURRENT_USER: "users/current-user/",

  //qualification
  QUALIFICATIONS: "masters/company-qualifications",
  QUALIFICATION: "masters/qualifications",
  INITIATE_USER: "users/invites/",

  //Skills
  GET_SKILLS: "masters/skills/",
  POST_SKILLS: "masters/skills/",
  UPDATE_SKILLS: "masters/skills/",
  DELETE_SKILLS: "masters/skills/",



  //Events
  EVENT_TYPE: "masters/event-types/",
  //common
  GET_ROLES: "auth/companies/roles",

  //masters
  EMPLOYMENT_TYPES: "/masters/employment-types",
  SCREENING_QUESTIONS: "masters/screening-questions/",

  //jobs
  JOBPROFILES: "jobprofiles/",
  JOBPROFILE_STATUS: "status",

  //locations
  COUNTRIES: "locations/countries",
  CITIES: "locations/cities",
  STATES: "locations/states",

  //candidates
  CANDIDATE: "candidates/",
  RESUME: "/resume",
  CANDIDATES: "candidates/applications/",
  CANDIDATES_INTERVIEW_MODE: "candidates/mode-of-event-list",
  CANDIDATES_EVENTS: "/events",
  GENERAL_DETAILS: "/general-details",
  EMPLOYMENT_DETAILS: "/employment-details",
  EDUCATIONAL_DETAILS: "/education-details",
  QUESTIONS: "/question-details",
  CANDIDATES_SKILLS: "masters/candidate-status/",
  CANDIDATES_GENERAL_DETAILS: "careers/applications/general-details",
  CANDIDATES_PERVIOUS_EMPLOYMENT: "careers/applications/employment-details",
  CANDIDATES_EDUCATION_DETAILS: "careers/applications/education-details",
  CANDIDATES_SCREENING_DETAILS: "careers/applications/submit",
  CANDIDATE_FEEDBACK: "/feedbacks",
  EXPORT: "export",
  //Agency
  GET_AGENCY: "agencies/",
  POST_AGENCY: "agencies/",
  UPDATE_AGENCY: "agencies/",
  DELETE_AGENCY: "agencies/",
  DELETE_AGENCY_JOB: "agencies/",
  AGENCY_JOBPROFILES: "jobprofiles",

  //Candidates
  CANDIDATES_EMAIL_VERIFY: "candidates/email/verify",

};
