import React, { memo, Fragment, useState, Suspense } from 'react'
import LeftSideBar from './LeftSideBar'
import styles from './PostLogin.module.scss'
import { Outlet } from 'react-router-dom'
import Global from '../../components/Global'
import Spinner from '../../components/Spinner'
import { Cookies } from 'react-cookie'
import { COOKIES } from "../../utils/constants"

const Index = () => {
  const cookies = new Cookies();
  const [notesOpen, setNotesOpen] = useState(false);
  const handleNotesOpen = () => {
    setNotesOpen(true);
  }
  const handleNotesClose = () => {
    setNotesOpen(false);
  }
  console.log(cookies.get(COOKIES.ROLE), "cookies")
  return (
    <Fragment>
      <Global />
      <div className={styles.layout}>
        {window.location.pathname === "/career/38" && cookies.get(COOKIES.ROLE) === undefined ? "" : <LeftSideBar />}
        <div className={ window.location.pathname === "/career/38" && cookies.get(COOKIES.ROLE) === undefined ? styles.rightSideContainerFullWidth  : styles.rightSideContainer}>
          <Suspense fallback={<Spinner />}>
            <Outlet context={[setNotesOpen, notesOpen, handleNotesOpen, handleNotesClose]} />
          </Suspense>
        </div>
      </div>
    </Fragment>
  )
}

export default memo(Index)