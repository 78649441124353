import { API_ENDPOINTS } from "../../utils/APIEndpoints"
import axiosPrivate from "../../utils/axiosPrivate"
import { API_STATUS } from "../../utils/constants"

export const getEmploymentTypes = async()=>{
    try {
        const res = await axiosPrivate.get(API_ENDPOINTS.EMPLOYMENT_TYPES)
        if(res?.statusText === 'OK'){
            return{
                status: API_STATUS.SUCCESS,
                data: res?.data
            }
        }
    } catch (error) {
        console.log('error', error)
    }
}

export const getScreeningQuestions=async()=>{
    try {
        const res = await axiosPrivate.get(API_ENDPOINTS.SCREENING_QUESTIONS)
        if(res?.statusText === 'OK'){
            return{
                status: API_STATUS.SUCCESS,
                data: res?.data
            }
        }
    } catch (error) {
        console.log('error', error)
    }
}

export const saveScreeningQuestions=async(payload)=>{
    try {
        const res=await axiosPrivate.post(API_ENDPOINTS.SCREENING_QUESTIONS,payload)
        return {
            status: API_STATUS?.SUCCESS,
            data: res?.data,
            message:"Question Saved Successfully"
        }
    } catch (error) {
        console.log('error', error)
    }
}