import axios from "../lib/axios";
import { Cookies } from "react-cookie";
import { COOKIES } from "./constants";
import { PATH } from "./pagePath";
import { toast } from "react-hot-toast";

const axiosPrivate = axios;
const cookies = new Cookies();

const errorHandler = (err) => {
  if (typeof (err) == "string") {
    toast?.error(err)
  } else {
    const values = Object.values(err);
    values?.map((val) => {
      if (typeof (val) == "object") {
        let res = Object.values(val)
        let err = res?.flat()
        toast?.error(err[0])
      }
      else if (Array.isArray(val)) {
        val?.map((msg) => {
          toast?.error(msg);
        });
      } else {
        toast?.error(val)
      }
    });
  }


};
axiosPrivate.interceptors.request.use(
  (config) => {
    const accessToken = cookies.get(COOKIES.ACCESS_TOKEN);
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosPrivate.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 401) {
      prevRequest.sent = true;
      cookies.remove(COOKIES.ACCESS_TOKEN, { path: "/" });
      cookies.remove(COOKIES.REFERESH_TOKEN, { path: "/" });
      setTimeout(() => {
        window.location.href = PATH.LOGIN;
      }, 2000);
      // const refreshToken = cookies.get(COOKIES.REFERESH_TOKEN)
      // const newAccessToken = refreshToken //await refresh();
      // prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
      // return axiosPrivate(prevRequest);
    } else if (error?.response?.status === 403) {
      errorHandler(error?.response?.data);
      // cookies.remove(COOKIES.ACCESS_TOKEN,{path:'/'})
      // cookies.remove(COOKIES.REFERESH_TOKEN,{path:'/'})
      // setTimeout(()=>{
      //     window.location.href = PATH.LOGIN
      // },2000)
    } else if (error?.response?.status === 404) {
      toast.error(error?.message || "Request failed with status code 404");
    } else if (error?.response?.status === 400) {

      errorHandler(error?.response?.data);
    } else {
      toast.error(error?.message || "Network Error");
    }
    return Promise.reject(error);
  }
);

export default axiosPrivate;
