import { API_ENDPOINTS } from "../../utils/APIEndpoints"
import axiosPrivate from "../../utils/axiosPrivate"
import { API_STATUS } from "../../utils/constants"

export const getJobs=async(params)=>{
    try {
        const res=await axiosPrivate.get(API_ENDPOINTS.JOBPROFILES,params)

        if(res.statusText === "OK"){
            return {
                status: API_STATUS.SUCCESS,
                data: res?.data
            }
        }
    } catch (error) {
        console.log('error', error)
    }
}

export const addJob=async(payload)=>{
    try {
        const res=await axiosPrivate.post(API_ENDPOINTS.JOBPROFILES,payload)

        return {
            status: API_STATUS?.SUCCESS,
            data: res?.data,
            message:"Job created successfully"
        }
    } catch (error) {
        console.log('error', error)
    }
}

export const getJobDetail =async(id)=>{
    try {
        const res = await axiosPrivate.get(`${API_ENDPOINTS.JOBPROFILES}${id}`)

        if(res.statusText === "OK"){
            return {
                status: API_STATUS.SUCCESS,
                data: res?.data
            }
        }
    } catch (error) {
        return{
            status: API_STATUS.FAILED,
            data: error?.response?.data || error?.message
        }
    }
}

export const getJobStatus = async()=>{
    try {
        const res=await axiosPrivate.get(`${API_ENDPOINTS.JOBPROFILES}${API_ENDPOINTS.JOBPROFILE_STATUS}`)
        if(res.statusText === "OK"){
            return {
                status: API_STATUS.SUCCESS,
                data: res?.data
            }
        }
    } catch (error) {
        console.log('error', error)
    }
}

export const updateJobStatus = async(id,payload) =>{
    try {
        const res=await axiosPrivate.put(`${API_ENDPOINTS.JOBPROFILES}${id}/${API_ENDPOINTS?.JOBPROFILE_STATUS}`,payload)

        return{
            status: API_STATUS?.SUCCESS,
            message:"Job status updated successfully"
        }
    } catch (error) {
        console.log('error', error)
    }
}