export const PATH = {
  DEFAULT: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  INVITE_USER: "/user-invitation",
  LOGIN_WITH_OTP: "/login-with-otp",
  DASHBOARD: "/dashboard",
  CANDIDATES: "/candidates",
  APPLICATION: "/application",
  RESUME: "/resume",
  EVENTS: "/events",
  JOBS: "/jobs",
  CAREER: "/career",
  POSTED_JOB_VIEW: "/jobprofiles",
  STAGES: "/stages",
  SETTINGS: "/settings",
  FORGOT_PASSWORD: "/forgot-password",
  SET_PASSWORD: "/set-password",
  OTP: "/otp",
  ACCOUNT: "/settings/account",
  COMPANY: "/settings/company",
  SETTING_CANDIDATE: "/settings/candidate",
  SUBSCRIPTION: "/settings/subscription",
  USER: "/settings/user",
  TEAMS: "/settings/teams",
  AGENCY: "/settings/agency",
  RECRUITER: "/settings/recruiter",
  EDIT_EVENT_TYPE: "/settings/editEventType",
  QUALIFICATION: "/settings/qualification",
  SKILLS: "/settings/skills",
  STATUS: "/settings/status",
  PAGE_NOT_FOUND: "*",
  // CANDIDATE_APPLY: "/candidateApply"
};
