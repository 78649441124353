import { API_ENDPOINTS } from "../../utils/APIEndpoints"
import axiosPrivate from "../../utils/axiosPrivate"
import { API_STATUS } from "../../utils/constants"

export const getCountries=async()=>{
    try {
        const res=await axiosPrivate.get(API_ENDPOINTS.COUNTRIES)

        if(res.statusText === "OK"){
            return {
                status: API_STATUS.SUCCESS,
                data: res?.data
            }
        }
    } catch (error) {
        console.log('error', error)
    }
}

export const getState=async(params)=>{
    try {
        const res=await axiosPrivate.get(API_ENDPOINTS.STATES,params)

        if(res.statusText === "OK"){
            return {
                status: API_STATUS.SUCCESS,
                data: res?.data
            }
        }
    } catch (error) {
        console.log('error', error)
    }
}

export const getCities=async(params)=>{
    try {
        const res=await axiosPrivate.get(API_ENDPOINTS.CITIES,params)

        if(res.statusText === "OK"){
            return {
                status: API_STATUS.SUCCESS,
                data: res?.data
            }
        }
    } catch (error) {
        console.log('error', error)
    }
}