import React, { memo, useEffect, useState } from "react";
import styles from "./PostLogin.module.scss";
import LOGO from "../../assets/images/blackLogo.png";
import { sideBarNavigation } from "../../utils/sideBarNavigation";
import { NavLink, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
const LeftSideBar = (props) => {
  const location = useLocation();
  const [company, setCompany] = useState()
  const [cookies] = useCookies(["companyid"])
  useEffect(() => {
    const firstName = cookies.companyid;
    setCompany(firstName)
  }, [cookies])
  return (
    <div className={styles.leftSideContainer}>
      <div className={styles.logoContainer}>
        <img src={LOGO} className={styles.logo} />
      </div>
      <div className={styles.navContainer}>
        {sideBarNavigation.map((data) => (
          data?.company ? <NavLink
            key={data.id}
            className={({ isActive, isPending }) =>
              `${styles.navLink} ${isActive && styles.activeLink}`
            }
            to={`${data.path}/${company}`}
            state={{ previousPath: location.pathname }}
          >
            <img
              src={
                location?.pathname.includes(data?.path)
                  ? data.activeLogo
                  : data.inActiveLogo
              }
              className={styles.icon}
              alt="icons"
            />
            <p>{data.pageName}</p>
          </NavLink> :
            <NavLink
              key={data.id}
              className={({ isActive, isPending }) =>
                `${styles.navLink} ${isActive && styles.activeLink}`
              }
              to={data.path}
              state={{ previousPath: location.pathname }}
            >
              <img
                src={
                  location?.pathname.includes(data?.path)
                    ? data.activeLogo
                    : data.inActiveLogo
                }
                className={styles.icon}
                alt="icons"
              />
              <p>{data.pageName}</p>
            </NavLink>
        ))}
      </div>
    </div>
  );
};

export default memo(LeftSideBar);
