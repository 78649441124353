import { PATH } from "./pagePath";
import INACTIVE_DASHBOARD from '../assets/icons/inActiveDashboard.png';
import ACTIVE_DASHBOARD from '../assets/icons/activeDashboard.png';

import INACTIVE_CANDIDATE from '../assets/icons/inActiveCandidate.png';
import ACTIVE_CANDIDATE from '../assets/icons/activeCandidate.png';

import INACTIVE_JOB from '../assets/icons/inActiveJob.png';
import ACTIVE_JOB from '../assets/icons/inActiveJob.png';

import INACTIVE_CAREER from '../assets/icons/inActiveCareer.png';
import ACTIVE_CAREER from '../assets/icons/activeCareer.png';

import INACTIVE_STAGE from '../assets/icons/inActiveStage.png';
import ACTIVE_STAGE from '../assets/icons/inActiveStage.png';

import INACTIVE_SETTING from '../assets/icons/inActiveSetting.png';
import ACTIVE_SETTING from '../assets/icons/activeSetting.png';
import { PAGENAME } from "./constants";

export const sideBarNavigation = [
    {
        id: 1,
        path: PATH.DASHBOARD,
        inActiveLogo: INACTIVE_DASHBOARD,
        activeLogo: ACTIVE_DASHBOARD,
        pageName: PAGENAME.DASHBOARD
    },
    {
        id: 2,
        path: PATH.CANDIDATES,
        inActiveLogo: INACTIVE_CANDIDATE,
        activeLogo: ACTIVE_CANDIDATE,
        pageName: PAGENAME.CANDIDATES
    },
    {
        id: 3,
        path: PATH.JOBS,
        inActiveLogo: INACTIVE_JOB,
        activeLogo: ACTIVE_JOB,
        pageName: PAGENAME.JOBS
    },
    {
        id: 4,
        path: PATH.CAREER,
        inActiveLogo: INACTIVE_CAREER,
        activeLogo: ACTIVE_CAREER,
        pageName: PAGENAME.CAREER,
        company: true
    },
    {
        id: 5,
        path: PATH.STAGES,
        inActiveLogo: INACTIVE_STAGE,
        activeLogo: ACTIVE_STAGE,
        pageName: PAGENAME.STAGES
    },
    {
        id: 6,
        path: PATH.SETTINGS,
        inActiveLogo: INACTIVE_SETTING,
        activeLogo: ACTIVE_SETTING,
        pageName: PAGENAME.SETTINGS
    }

]