import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getCountriesAPI } from '../../store/globalReducer';
import Spinner from '../Spinner';

const Index = () => {
    const countries=useSelector(state=>state?.global?.countries);
    const loader = useSelector(state => state?.global?.loader)

    const dispatch=useDispatch()

    useEffect(()=>{
        if(!countries?.length){
            dispatch(getCountriesAPI())
        }
    },[])

    return loader && <Spinner />
}

export default Index