import { configureStore } from '@reduxjs/toolkit'
import globalReducer from './globalReducer'
import masterReducer from './masterReducer'
import jobReducer from './jobReducer'

export const store = configureStore({
  reducer: {
    global: globalReducer,
    master: masterReducer,
    job: jobReducer,
  },
})