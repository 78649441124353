import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getEmploymentTypes } from "../services/master/service"

const initialState = {
    employmentTypes: []
}

export const getEmploymentTypesAPI=createAsyncThunk(
    'masters/getEmploymentTypes',
    async()=>{
        const res = await getEmploymentTypes()
        return{
            data: res?.data || []
        }
    }
)

export const masterSlice=createSlice({
    name:'master',
    initialState,
    reducers:{

    },
    extraReducers: (builder)=>{
        builder.addCase(getEmploymentTypesAPI?.fulfilled,(state,action)=>{
            state.employmentTypes = action.payload?.data
        })
    }
})

// export const {} = masterSlice.actions

export default masterSlice.reducer