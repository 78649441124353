import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getCountries } from "../services/locations/service"

const initialState = {
    loader: false,
    page: 1,
    countries: [],
    notesOpen: true,
    user: [],
    recruiter: [],
    count: 0,
    render: 0,
    searchCandidate: "",
    searchTechnology: [],

}

export const getCountriesAPI = createAsyncThunk(
    'global/getCountriesAPI',
    async () => {
        const res = await getCountries()

        return {
            data: res?.data?.map(data => {
                return {
                    label: data?.name,
                    value: data?.id
                }
            }) || []
        }
    }
)

export const gloablSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setNotesOpen: (state, action) => {
            state.notesOpen = action.payload
        },
        setuser: (state, action) => {
            state.user = action.payload
        },
        setrecruiter: (state, action) => {
            state.recruiter = action.payload
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setCount: (state, action) => {
            state.count = action.payload
        },
        setRender: (state, action) => {
            state.render = action.payload       
        },
        setSearchCandidate: (state, action) => {
            state.searchCandidate = action.payload
        },
        setSearchTechnology: (state, action) => {
            state.searchTechnology = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCountriesAPI?.fulfilled, (state, action) => {
            state.countries = action.payload?.data
        })
    }
})

export const { setLoader, setNotesOpen, setuser, setrecruiter, setPage, setCount, setRender ,setSearchCandidate, setSearchTechnology } = gloablSlice.actions

export default gloablSlice.reducer