import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, useLocation } from "react-router-dom";
import { router } from "./router";
import { Provider } from "react-redux";
import { store } from "./store";
import Spinner from "./components/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import "./global.scss";
import "react-quill/dist/quill.snow.css";
import { CookiesProvider } from "react-cookie";
import { Toaster } from "react-hot-toast";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      <CookiesProvider>
        <Provider store={store}>
          {/* <Suspense fallback={<Spinner />}> */}
            <Toaster position="top-right" reverseOrder={false} />
            <RouterProvider router={router} />
          {/* </Suspense> */}
        </Provider>
      </CookiesProvider>
    </GoogleOAuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
