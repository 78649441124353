import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getJobStatus } from "../services/jobs/service";

const initialState = {
  status: [],
};

export const getStatusAPI = createAsyncThunk("job/status", async () => {
  const res = await getJobStatus();

  return {
    data: res?.data || [],
  };
});

export const jobSlice = createSlice({
  name: "job",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getStatusAPI?.fulfilled, (state, action) => {
      state.status = action.payload?.data;
    });
  },
});

export default jobSlice.reducer;
