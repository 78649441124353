export const PAGENAME = {
  DASHBOARD: "Dashboard",
  CANDIDATES: "Candidates",
  JOBS: "Jobs",
  CAREER: "Career",
  STAGES: "Stages",
  SETTINGS: "Settings",
};

export const PAGINATION = {
  ROWS_PER_PAGE: [10, 15, 20, 25, 30],
  DEFAULT_ROWS_PER_PAGE: 20,
  DEFAULT_CURRENT_PAGE: 1,
  NO_BREAK_PAGE: 4,
};

export const SETTINGS_PAGENAME = {
  ACCOUNT: "Account",
  COMPANY: "Company",
  CANDIDATE: "Candidate",
  SUBSCRIPTION: "Subscription",
  USER: "User",
  TEAMS: "Teams / Stage",
  AGENCY: "Agency",
  RECRUITER: "Recruiter",
  JOBS: "Jobs",
  EDIT_EVENT_TYPE: "Edit Event Type",
  QUALIFICATION: "Customized Qualification",
  SKILLS: "Skills",
};

export const CANDIDATES_PAGENAME = {
  APPLICATION: "Application",
  RESUME: "Resume",
  EVENTS: "Events",
};

export const FILLED_ICON = "FILLED_ICON";

export const SCREENING_QUESTIONS = [
  {
    value: "1",
    label: "Open Text",
  },
  {
    value: "2",
    label: "Single Choice",
  },
  {
    value: "3",
    label: "Multiple Choice",
  },
];
export const COOKIES = {
  ACCESS_TOKEN: "accessToken",
  REFERESH_TOKEN: "refreshToken",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  EMAIL_ADDRESS: "emailAddress",
  PROFILE_COLOR: "profileColor",
  APPLICATION_TOKEN: "applicationToken",
  JOBPROFILE: "jobprofile",
  COMPANY_ID: "companyid",
  USER_ID: "userid",
  PERMISSIONS: 'permissions',
  ROLE : "role"
};
export const VALIDATION = {
  MAX_LENGTH: 20,
  MAX_COMPANY_NAME_LENGTH: 50,
  MIN_SKILLS: 2,
  MAX_SKILLS: 30,
  PASSWORD_MIN_LENGTH: 8,
  Max_EMAIL_LENGTH: 250,
  MAX_AGENCY_lENGTH : 100,
  MAX_QUALIFICATION_lENGTH : 100,
  MAX_POSITION_lENGTH : 50,
  MAX_NAME_LENGTH : 20,
};

export const API_STATUS = {
  SUCCESS: "Success",
  FAILED: "Failed",
};

export const CTC_START = [
  { value: 100000, label: "1,00,000" },
  { value: 200000, label: "2,00,000" },
  { value: 300000, label: "3,00,000" },
  { value: 400000, label: "4,00,000" },
  { value: 500000, label: "5,00,000" },
  { value: 600000, label: "6,00,000" },
  { value: 700000, label: "7,00,000" },
  { value: 800000, label: "8,00,000" },
  { value: 900000, label: "9,00,000" },
  { value: 1000000, label: "10,00,000" },
  { value: 1100000, label: "11,00,000" },
  { value: 1200000, label: "12,00,000" },
  { value: 1300000, label: "13,00,000" },
  { value: 1400000, label: "14,00,000" },
  { value: 1500000, label: "15,00,000" },
];

export const CTC_END = [
  { value: 200000, label: "2,00,000" },
  { value: 300000, label: "3,00,000" },
  { value: 400000, label: "4,00,000" },
  { value: 500000, label: "5,00,000" },
  { value: 600000, label: "6,00,000" },
  { value: 700000, label: "7,00,000" },
  { value: 800000, label: "8,00,000" },
  { value: 900000, label: "9,00,000" },
  { value: 1000000, label: "10,00,000" },
  { value: 1100000, label: "11,00,000" },
  { value: 1200000, label: "12,00,000" },
  { value: 1300000, label: "13,00,000" },
  { value: 1400000, label: "14,00,000" },
  { value: 1500000, label: "15,00,000" },
  { value: 1600000, label: "16,00,000" },
  { value: 1700000, label: "17,00,000" },
  { value: 1800000, label: "18,00,000" },
  { value: 1900000, label: "19,00,000" },
  { value: 2000000, label: "20,00,000" },
];

export const EXPERIENCE_FROM = [
  { value: 0, label: "0 Year" },
  { value: 1, label: "1 Year" },
  { value: 2, label: "2 Year" },
  { value: 3, label: "3 Year" },
  { value: 4, label: "4 Year" },
  { value: 5, label: "5 Year" },
  { value: 6, label: "6 Year" },
  { value: 7, label: "7 Year" },
  { value: 8, label: "8 Year" },
  { value: 9, label: "9 Year" },
  { value: 10, label: "10 Year" },
];

export const EXPERIENCE_TO = [
  { value: 1, label: "1 Year" },
  { value: 2, label: "2 Year" },
  { value: 3, label: "3 Year" },
  { value: 4, label: "4 Year" },
  { value: 5, label: "5 Year" },
  { value: 6, label: "6 Year" },
  { value: 7, label: "7 Year" },
  { value: 8, label: "8 Year" },
  { value: 9, label: "9 Year" },
  { value: 10, label: "10 Year" },
  { value: 11, label: "11 Year" },
  { value: 12, label: "12 Year" },

];

export const hours = [
  { value: 0, label: "00" },
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
];

export const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July',
  'August', 'September', 'October', 'November', 'December'
];

export const dayNames = [
  'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday',
  'Friday', 'Saturday'
];

export const ACTIONS = {
	ADD_AGENCY: 'add_agency',
	ADD_AUTHUSEROTP: 'add_authuserotp',
	ADD_CANDIDATE: 'add_candidate',
	ADD_CANDIDATE_FEEDBACK: 'add_candidate_feedback',
	ADD_CANDIDATESKILL: 'add_candidateskill',
	ADD_CONTENTTYPE: 'add_contenttype',
	ADD_EXCEPTIONLOG: 'add_exceptionlog',
	ADD_GROUP: 'add_group',
	ADD_JOBPROFILE: 'add_jobprofile',
	ADD_LOGENTRY: 'add_logentry',
	ADD_PERMISSION: 'add_permission',
	ADD_REQUESTLOG: 'add_requestlog',
	ADD_ROLE: 'add_role',
	ADD_SCREENINGQUESTION: 'add_screeningquestion',
	ADD_SCREENINGQUESTIONCHOICE: 'add_screeningquestionchoice',
	ADD_SESSION: 'add_session',
	ADD_SUBSCRIPTIONPAYMENT: 'add_subscriptionpayment',
	ADD_SUBSCRIPTIONPLAN: 'add_subscriptionplan',
	ADD_TEAM: 'add_team',
	ADD_TEAMMEMBER: 'add_teammember',
	ADD_TEAMSTAGERELATION: 'add_teamstagerelation',
	ADD_USER: 'add_user',
	ASSIGN_JOBPROFILE: 'assign_jobprofile',
	CHANGE_AGENCY: 'change_agency',
	CHANGE_AUTHUSEROTP: 'change_authuserotp',
	CHANGE_CANDIDATE: 'change_candidate',
	CHANGE_CANDIDATE_STAGE: 'change_candidate_stage',
	CHANGE_CANDIDATE_STATUS: 'change_candidate_status',
	CHANGE_CANDIDATESKILL: 'change_candidateskill',
	CHANGE_COMPANY_INFO: 'change_company_info',
	CHANGE_CONTENTTYPE: 'change_contenttype',
	CHANGE_EXCEPTIONLOG: 'change_exceptionlog',
	CHANGE_GROUP: 'change_group',
	CHANGE_JOBPROFILE: 'change_jobprofile',
	CHANGE_LOGENTRY: 'change_logentry',
	CHANGE_PERMISSION: 'change_permission',
	CHANGE_REQUESTLOG: 'change_requestlog',
	CHANGE_ROLE: 'change_role',
	CHANGE_SCREENINGQUESTION: 'change_screeningquestion',
	CHANGE_SCREENINGQUESTIONCHOICE: 'change_screeningquestionchoice',
	CHANGE_SESSION: 'change_session',
	CHANGE_SUBSCRIPTIONPAYMENT: 'change_subscriptionpayment',
	CHANGE_SUBSCRIPTIONPLAN: 'change_subscriptionplan',
	CHANGE_TEAM: 'change_team',
	CHANGE_TEAMMEMBER: 'change_teammember',
	CHANGE_TEAMSTAGERELATION: 'change_teamstagerelation',
	CHANGE_USER: 'change_user',
	CHANGE_USER_ROLE: 'change_user_role',
	DELETE_AGENCY: 'delete_agency',
	DELETE_AUTHUSEROTP: 'delete_authuserotp',
	DELETE_CANDIDATE: 'delete_candidate',
	DELETE_CANDIDATESKILL: 'delete_candidateskill',
	DELETE_CONTENTTYPE: 'delete_contenttype',
	DELETE_EXCEPTIONLOG: 'delete_exceptionlog',
	DELETE_GROUP: 'delete_group',
	DELETE_JOBPROFILE: 'delete_jobprofile',
	DELETE_LOGENTRY: 'delete_logentry',
	DELETE_PERMISSION: 'delete_permission',
	DELETE_REQUESTLOG: 'delete_requestlog',
	DELETE_ROLE: 'delete_role',
	DELETE_SCREENINGQUESTION: 'delete_screeningquestion',
	DELETE_SCREENINGQUESTIONCHOICE: 'delete_screeningquestionchoice',
	DELETE_SESSION: 'delete_session',
	DELETE_SUBSCRIPTIONPAYMENT: 'delete_subscriptionpayment',
	DELETE_SUBSCRIPTIONPLAN: 'delete_subscriptionplan',
	DELETE_TEAM: 'delete_team',
	DELETE_TEAMMEMBER: 'delete_teammember',
	DELETE_TEAMSTAGERELATION: 'delete_teamstagerelation',
	DELETE_USER: 'delete_user',
	INVITE_USER: 'invite_user',
	UNASSIGN_JOBPROFILE: 'unassign_jobprofile',
	VIEW_AGENCY: 'view_agency',
	VIEW_AUTHUSEROTP: 'view_authuserotp',
	VIEW_CANDIDATE: 'view_candidate',
	VIEW_CANDIDATE_FEEDBACK: 'view_candidate_feedback',
	VIEW_CANDIDATE_SALARY: 'view_candidate_salary',
	VIEW_CANDIDATESKILL: 'view_candidateskill',
	VIEW_COMPANY_INFO: 'view_company_info',
	VIEW_CONTENTTYPE: 'view_contenttype',
	VIEW_EXCEPTIONLOG: 'view_exceptionlog',
	VIEW_GROUP: 'view_group',
	VIEW_JOBPROFILE: 'view_jobprofile',
	VIEW_LOGENTRY: 'view_logentry',
	VIEW_PERMISSION: 'view_permission',
	VIEW_REQUESTLOG: 'view_requestlog',
	VIEW_ROLE: 'view_role',
	VIEW_SCREENINGQUESTION: 'view_screeningquestion',
	VIEW_SCREENINGQUESTIONCHOICE: 'view_screeningquestionchoice',
	VIEW_SESSION: 'view_session',
	VIEW_SUBSCRIPTIONPAYMENT: 'view_subscriptionpayment',
	VIEW_SUBSCRIPTIONPLAN: 'view_subscriptionplan',
	VIEW_TEAM: 'view_team',
	VIEW_TEAMMEMBER: 'view_teammember',
	VIEW_TEAMSTAGERELATION: 'view_teamstagerelation',
	VIEW_USER: 'view_user',
};